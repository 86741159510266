<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Determine the nuclear process illustrated by each of the equations below:</p>

      <v-row class="pl-6">
        <v-col cols="3" class="mt-2">
          <chemical-latex content="^{230}_{90}Th + ^{4}_{2}He -> ^{226}_{98}Cf" />
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="inputs.inputA"
            class="ml-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </v-col>
      </v-row>

      <v-row class="pl-6">
        <v-col cols="3" class="mt-2">
          <chemical-latex content="^{13}_{7}N -> ^{13}_{6}C + ^{0}_{+1}e^{+}" />
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="inputs.inputB"
            class="ml-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </v-col>
      </v-row>

      <v-row class="pl-6">
        <v-col cols="3" class="mt-2">
          <chemical-latex content="^{103}_{46}Th + ^{0}_{-1}e^{-} -> ^{103}_{45}Rh" />
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="inputs.inputC"
            class="ml-3"
            style="width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </v-col>
      </v-row>

      <v-row class="pl-6">
        <v-col cols="3" class="mt-2">
          <chemical-latex content="^{16}_{7}N -> ^{16}_{8}O + ^{0}_{-1}e^{-}" />
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="inputs.inputD"
            class="ml-3"
            style="max-width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </v-col>
      </v-row>

      <v-row class="mb-n3 pl-6">
        <v-col cols="3" class="mt-2">
          <chemical-latex content="^{60}_{27}Co^* -> ^{60}_{27}Co + \gamma" />
        </v-col>
        <v-col cols="9">
          <v-select
            v-model="inputs.inputE"
            class="ml-3"
            style="max-width: 240px"
            dense
            :items="items"
            label="Select Option"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220A4Q6',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: null,
        inputE: null,
      },
      items: [
        {text: 'alpha emission', value: 'alpha'},
        {text: 'beta emission', value: 'beta'},
        {text: 'electron capture', value: 'electron'},
        {text: 'gamma emission', value: 'gamma'},
        {text: 'positron emission', value: 'positron'},
      ],
    };
  },
};
</script>
